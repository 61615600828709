/**
 * Created by borysyudin on 14.02.17.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Story from './Story'
import ContentLoading from './ContentLoading'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { MAIN_URL, PUBLIC_URL } from '../actions/constants'
import {
  XIcon,
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from 'react-share'

import '../css/StoryList.css'

class StoryList extends Component {
  constructor(props) {
    super(props)
    this.stage = { updateTwitterHack: new Date().getTime() }
  }

  render() {
    const {
      pagination,
      loadNextData,
      moveToArchive,
      stories,
      auth,
      archived,
    } = this.props

    const stories_ = stories.map((story, index) => (
      <Story
        moveToArchive={moveToArchive}
        key={index}
        story={story}
        actionsURL={MAIN_URL + this.props.actionsURL}
        comments={true}
      />
    ))

    const stories__ =
      stories_.length === 0 ? (
        <div className="no-stories">Archive empty</div>
      ) : (
        <InfiniteScroll
          next={loadNextData}
          hasMore={pagination && Boolean(pagination.next_url)}
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={stories_.length}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {stories_}
          </ReactCSSTransitionGroup>
          {pagination && pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      )

    return (
      <div className="page-content">
        {archived && (
          <div className="share-buttons">
            <TwitterShareButton
              className="x-share-button"
              title=""
              url={`${PUBLIC_URL}/users/${auth.user.id}?${this.stage.updateTwitterHack}`}
            >
              {' '}
              <XIcon
                className="x-share-button-icon"
                size={32}
                round={false}
                borderRadius={14}
              />
              Tweet
            </TwitterShareButton>
            <FacebookShareButton
              className="facebook-share-button"
              title="Check out my nuuz!"
              url={`${PUBLIC_URL}/users/${auth.user.id}?${this.stage.updateTwitterHack}`}
            >
              {' '}
              <svg
                viewBox="0 0 64 64"
                width="32"
                height="32"
                className="facebook-share-button-icon"
              >
                <circle cx="32" cy="32" r="31" fill="#ffffff" />
                <path
                  d="M 35.8898 68.7496 L 23.9918 68.7496 L 23.9918 40.6935 L 18.0466 40.6935 L 18.0466 31.0261 L 23.9918 31.0261 L 23.9918 25.2224 C 23.9918 17.3363 27.3471 12.6443 36.8857 12.6443 L 44.825 12.6443 L 44.825 22.3152 L 39.863 22.3152 C 36.1497 22.3152 35.9042 23.666 35.9042 26.1873 L 35.8879 31.0261 L 44.8795 31.0261 L 43.8271 40.6935 L 35.8879 40.6935 L 35.8879 68.7496 L 35.8898 68.7496 Z"
                  fill="#1877f2"
                  id="facebook-share-icon"
                />
              </svg>
              <span style={{ fontWeight: 'bold' }}>Share</span>
            </FacebookShareButton>
            <LinkedinShareButton
              className="linkedin-share-button"
              title="Check out my nuuz!"
              url={`${PUBLIC_URL}/users/${auth.user.id}?${this.stage.updateTwitterHack}`}
            >
              {' '}
              <svg
                viewBox="0 0 64 64"
                width="32"
                height="32"
                className="linkedin-share-button-icon"
              >
                <rect width="64" height="64" rx="14" ry="14" fill="#ffffff" />
                <path
                  d="M 21.8443 28.5166 L 25.5865 28.5166 L 25.5865 41.5588 L 21.8443 41.5588 L 21.8443 28.5166 Z M 23.62 26.8852 L 23.593 26.8852 C 22.2383 26.8852 21.36 25.8882 21.36 24.6257 C 21.36 23.3374 22.2642 22.36 23.646 22.36 C 25.0266 22.36 25.8756 23.3349 25.9026 24.622 C 25.9026 25.8845 25.0266 26.8852 23.62 26.8852 L 23.62 26.8852 Z M 40.56 41.56 L 36.3165 41.56 L 36.3165 34.8097 C 36.3165 33.0431 35.6528 31.8383 34.1931 31.8383 C 33.0766 31.8383 32.4558 32.6509 32.1668 33.4365 C 32.0584 33.7167 32.0753 34.1089 32.0753 34.5023 L 32.0753 41.56 L 27.8714 41.56 C 27.8714 41.56 27.9255 29.6033 27.8714 28.5166 L 32.0753 28.5166 L 32.0753 30.5635 C 32.3237 29.6685 33.667 28.3912 35.8108 28.3912 C 38.4705 28.3912 40.56 30.2684 40.56 34.3068 L 40.56 41.56 L 40.56 41.56 Z"
                  fill="#2d72ac"
                  id="linkedin-share-icon"
                />
              </svg>
              <span style={{ fontWeight: 'bold' }}>Share</span>
            </LinkedinShareButton>
          </div>
        )}
        {stories__}
      </div>
    )
  }
}

StoryList.propTypes = {
  loadNextData: PropTypes.func,
  pagination:   PropTypes.object.isRequired,
  data:         PropTypes.object,
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, {})(StoryList)
